@import url('https://fonts.googleapis.com/css?family=Oswald:300,400,500,700&display=swap&subset=latin-ext');

@mixin breakpoint($breakpoint) {
  @media screen and (min-width: $breakpoint) {
    @content;
  }
}
@mixin tablet {
  @include breakpoint(481px) {
    @content;
  }
}
@mixin desktop {
  @include breakpoint(961px) {
    @content;
  }
}

%wrapper {
  max-width: 1170px;
  padding: 0 15px;
  margin: 0 auto;
}
%flex {
  display:flex;
  flex-wrap:wrap;
  align-items:center;

}
%flex-column {
  @extend %flex;
  width: 100%;
  flex-direction: column;
  align-items: stretch;
}

%sr {
  position:absolute;
  margin: -1px;
  height:0;
  width:0;
  overflow:hidden;
  clip: rect(0 0 0 0);
  &.focusable:focus {
    position:static;
    margin: auto;
    height:auto;
    width:auto;
    overflow:visible;
    clip: none;
  }
}

* {
  &,
  &::before,
  &::after {
    box-sizing:inherit;
    margin: 0;
    padding:0;
  }
}

html {
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: 'Oswald', sans-serif;
  font-weight: 300;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

a {
  cursor: pointer;
  transition: all 0.3s linear;
}

p:not(:last-child) {
  margin-bottom:15px;
}
h1,
.title {
  font-weight: 500;
  text-transform:uppercase;
}
.title {
  text-align:center;
  margin-bottom: 15px;
  &.left {
    text-align:left;
  }
}
.section-title {
  @extend .title;
  padding-bottom: 10px;
  border-bottom: 1px solid rgb(247, 217, 0);
  &:first-letter {
    //color: #808080;
    font-size: 1.3em;
  }
}

.icon {
  display:block;
  text-align:center;
  margin:15px 0;
  color: #808080;
}

.btn {
  display:inline-block;
  padding: 10px 15px;
  vertical-align: middle;
  font-weight: 500;
  color: #000;
  background: #808080;
  text-decoration:none;
  border:none;
  border-radius: 10px;
  font-weight: 500;
  line-height: 1.2;
  font-family:inherit;
  transition: all 0.3s linear;
  cursor: pointer;
  &:hover,
  &:focus {
    background: rgb(247, 217, 0);
  }
  &:active {
    position: relative;
    top: 3px;
    left: 3px;
  }
  @at-root .header-hero & {
    text-shadow: none;
    box-shadow: #000 3px 3px;
    &:active {
      box-shadow:none;
    }
  }
  & + & {
    margin-left: 30px;
  }
}
.btn-big {
  @extend .btn;
  font-size: 1.5rem;
  overflow: hidden;
  &.square {
    padding: 15px;
    width: 185px;
    height:185px;
    margin-top: 15px;
    margin-bottom: 15px;
    img {
      display:block;
      margin: 0;
      &.nomargin {
        display: block;
        margin: -15px -15px 0 ;
        width:185px;
        max-width:none;
        height: auto;
      }
    }
    span {
      display:block;
      width: 155px;
      height: 65px;
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: -15px;
    }
  }
}

.preloader {
  display:none;
  position:fixed;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  z-index: 9999999;
  background: #fff;
  justify-content: center;
  align-items:center;
  font-size: 5rem;
  &-text {
    @extend %sr;
  }
  @at-root .js & {
    display:flex;
  }
}

[hidden] {
  display:none;
}

.columns {
  @extend %flex;
  align-items: center;
  margin: 0 -15px -15px;
}
.col {
  & {
    flex: 0 1 auto;
    width: 100%;
    padding: 0 15px 15px;
    @include desktop {
      width: auto;
    }
  }
  &-2 {
    @extend .col;
    @include desktop {
      width: 50%;
    }
  }
  &-3 {
    @extend .col;
    @include desktop {
      width: 33.333%;
    }
  }
  &-2-3 {
    @extend .col;
    @include desktop {
      width: 66.666%;
    }
  }
  &-full {
    @extend .col;
    @include desktop {
      width: 100%;
    }
  }
  &.right {
    margin-left: auto;
  }
}


.skip-link {
  position: fixed;
  top:0;
  left:0;
  z-index:3;
}
.skip-link a{
  @extend %sr;
}

.background {
  position: fixed;
  top:0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background: #000 url('../img/background.jpg')  50% 0 no-repeat;
  background-size: cover;
}

.header {
  @extend %flex-column;
  min-height: 100vh;
  background: transparent
}

.header-hero{
  @extend %wrapper;
  @extend %flex;
  align-items: center;
  width: 100%;
  height: 100vh;
  color: #fff;
  text-shadow: #000 3px 3px;
  .columns {
    border: 1px solid transparent;
    border-top-color: #808080;
    border-bottom-color: rgb(247, 217, 0);;
  }
}
.brand {
  display:block;
  text-transform: uppercase;
  text-align: center;
  font-style: italic;
  font-weight: 700;
  font-size:3rem;
  color:rgb(247, 217, 0);

  @include desktop {
    text-align: right;
    font-size:7rem;
  }
}
.features {
  margin-left: 20px;
  font-size: 1.5rem;
  list-style-type: '\1f5f8\0020';
  li::marker {
    color: #808080;
  }
  @for $i from 1 through 3 {
    li:nth-child(#{$i}) {
      margin-left: 15px * $i;
    }
  }
  @include desktop {
    font-size: 2rem;
  }
}
.call-to-action {
  text-align:center;
}
.header-top {
  width: 100%;
  order:-1;
  position:fixed;
  top:0;
  z-index: 2000;
  width: 100%;
  background: #fff;
  color: #000;
  padding: 15px 0;
  transition: all 0.3s ease-in;
  text-transform: uppercase;
  font-size: 1.2rem;
  box-shadow: rgba(#000,0.5) 0 0 10px;
  &.transparent {
    background: transparent;
    color: #fff;
    text-shadow: #000 1px 1px;
    box-shadow: none;
  }
}

.navbar {
  @extend %wrapper;
  @extend %flex;
}

.home-link {
  position:relative;
  transition: all 0.3s ease-in;
  top: 0;
  font-size: 1.5rem;
  color: inherit;
  text-decoration: none;
  font-weight: 700;
  &:hover,
  &:focus {
    color: #808080;
  }
  @at-root .transparent & {
    top: -100px;
  }
}

.nav-toggle {
  margin-left: auto;
  border:none;
  background:inherit;
  color: inherit;
  font-size: inherit;
  font-weight: 300;
  font-family: inherit;
  text-shadow:inherit;
  cursor: pointer;
  transition: all 0.3s linear;
  &:hover,
  &:focus {
    color: #808080;
  }

}
.nav-list {
  @extend %flex;
  margin-left: auto;
  list-style:none;
  flex: 1 0 100%;
  &.mobile {
    background: #fff;
    color: #000;
    text-shadow:none;
  }
  @include breakpoint(651px) {
    flex: 0 1 auto;
    background:none;
    color: inherit;
  }
}
.nav-item {
  flex: 1 0 100%;
  padding: 10px 15px;
  border-bottom: 1px solid #808080;
  text-align: center;
  &:last-child {
    border: none;
  }
  @include breakpoint(651px) {
    flex: 0 1 auto;
    margin-left: 15px;
    padding: 0;
    border: none;
    &::after {
      content: '/';
      color: rgb(247, 217, 0);;
      text-shadow:none;
      margin-left: 15px;
    }
    &:last-child::after {
      content:'';
      margin-left:0;
    }
  }
}
.nav-link{
  text-decoration: none;
  color: inherit;
  transition: all 0.3s linear;
  &:hover,
  &:focus {
    color: #808080;
  }
}
.container{
  @extend %wrapper;
}

.section {
  padding: 100px 0;
  background: #fff;
  &.with-divider {
    position: relative;
    margin-bottom:300px;
  }

  &#kontakt {
    position:relative;
  }
  a {
    color: #808080;
    font-weight: 500;
    &:hover,
    &:focus {
      color: #000;
    }
  }
  ul,ol {
    padding-left: 15px;
  }
  ul {
    list-style-type: '\1f5f8\0020';
    li::marker {
      color: #808080;
    }
  }
}

.counter {
  background: rgb(247, 217, 0);
  color: #000;
  text-align: center;
  font-size: 1.5em;
  .number {
    font-size: 3em;
    font-weight: 700;
  }
}

.map {
  height: 300px;
  margin: -100px 0 100px;
  overflow: hidden;
  position: relative;
  z-index: 1 !important;
  @include desktop {
    position: absolute;
    margin: 0;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
  }
  .leaflet-top {
    top: 100px;
  }
}

.form {
  label {
    & {
      font-size: 0.9rem;
      font-weight:500;
    }
    // &:not(.checkbox-label) {
    //   @extend %sr;
    // }
    &.checkbox-label {
      display:inline-block;
      margin-left: 25px;
    }
  }
  .required {
    font-weight:700;
    color:#f00;
    font-variant:none;
    text-decoration:none;
  }
  input:not([type="checkbox"]),
  textarea {
    display:block;
    width: 100%;
    padding: 10px 15px;
    font-family: inherit;
    &::-webkit-input-placeholder {
      color: #808080;
    }
    &::-moz-placeholder {
      color: #808080;
    }
    &::placeholder {
      color: #808080;
    }
  }
  input[type="checkbox"] {
    margin-left: -25px;
    margin-right: 10px;
  }
  a {
    color: #808080;
  }
  .status {
    .alert {
      padding: 15px;
      &-error {
        @extend .alert;
        background: rgba(#f00,0.5);
        border:1px solid #f00;
      }
      &-success {
        @extend .alert;
        background: rgba(#008000,0.5);
        border:1px solid #008000;
      }
    }
  }
}

.footer {
  background: #000;
  color: #fff;
  padding: 100px 0;
  .columns {
    align-items: flex-start;
  }
  dl {
    @extend .columns;
  }
  dt {
    @extend .col-3;
    font-weight: 500;
    color: rgb(247, 217, 0);
  }
  dd {
    @extend .col-2-3;
  }
}

.authors {
  text-align: center;
  margin-top: 30px;
  a {
    color: #808080;
  }
}

.back-to-top-link {
  position: fixed;
  bottom: 15px;
  right: 15px;
  color: #808080;
  span:last-child {
    @extend %sr;
  }
}
